import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

function SEO({ description, lang, image, meta, keywords, title, pathname }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        const defaultImage = `${data.site.siteMetadata.siteUrl}${data.site.siteMetadata.defaultImage}`
        const metaImage = image?.images?.fallback?.src ?? defaultImage
        const metaUrl = `${data.site.siteMetadata.siteUrl}${pathname}`
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s`}
          >
            <meta name="title" content={title} />
            <meta property="og:url" content={metaUrl} />
            <meta
              property="og:title"
              content={title || data.site.siteMetadata.title}
            />
            <meta property="og:description" content={metaDescription} />
            <meta name="description" content={metaDescription} />
            <meta property="og:type" content="website" />
            <meta
              property="twitter:creator"
              content={`@${data.site.siteMetadata.social.twitter}`}
            />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={metaDescription} />
            <meta
              name="google-site-verification"
              content={process.env.GOOGLE_SITE_VERIFICATION_ID}
            />
            {(metaImage || defaultImage) && (
              <meta property="og:image" content={metaImage} />
            )}
            {(metaImage || defaultImage) && (
              <meta property="twitter:image" content={metaImage} />
            )}
            {(metaImage || defaultImage) && (
              <meta property="og:image:alt" content={title} />
            )}
            {(metaImage || defaultImage) && (
              <meta
                property="og:image:width"
                content={image ? image.width : 1232}
              />
            )}
            {(metaImage || defaultImage) && (
              <meta
                property="og:image:height"
                content={image ? image.height : 613}
              />
            )}
            {(metaImage || defaultImage) && (
              <meta property="twitter:card" content="summary_large_image" />
            )}
            {(metaImage || defaultImage) && (
              <meta property="twitter:card" content="summary" />
            )}
            {keywords.length > 0 && (
              <meta name="keywords" content={keywords.join(', ')} />
            )}
            {meta.map(({ name, content }, i) => (
              <meta key={i} name={name} content={content} />
            ))}
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  pathname: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.object,
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })
  ),
  keywords: PropTypes.arrayOf(PropTypes.string),
  pathname: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        siteUrl
        description
        author
        social {
          twitter
        }
        defaultImage: image
      }
    }
    defaultImage: file(relativePath: { eq: "images/jdd-cover.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
